header{
    .logo {
        img {
            height: 30px;
        }
    }
    .main-menu-area{
        .nav-menu {
            li a.btn-light{
                background: #fff;
                color:#7c4fe0;
                &:hover{
                    background: #7c4fe0;
                    color:#fff; 
                    text-decoration: none;
                    span:after{
                        display: none !important;
                    }
                }
            }
        }
        &.fixed-menu{
            .nav-menu {
                li a.btn-light{
                    background: #7c4fe0;
                    color:#fff; 
                    &:hover{
                        background: #fff;
                        color:#7c4fe0;
                    }
                }
            }
        }
    }
} 

footer{
    .footer-logo {
        img {
            height: 50px;
        }
    }
}

.text-primary {
    color:#7c4fe0 !important;
}

.banner{
    .btn-light{
        background: #fff;
        color:#7c4fe0;
        &:hover{
            background: #7c4fe0;
            color:#fff; 
            text-decoration: none; 
        }
    }
    .banner-image{
        width: 100%;
    }
}

#google-map{
    .content{
        min-height: 120px;
        .firstHeading{  
            font-size: 15px;
            text-transform: uppercase;
        }
        .bodyContent{ 
            width: 200px;
            p {
                font-size: 13px;
                line-height: 1.5;
                margin-bottom: 10px;
            }
            .contact{
                font-size: 13px;
                line-height: 1.1;
                .ion-icon { 
                    display: inline-block;
                    font-size: 18px;
                    position: relative;
                    top: 2px;
                    width: 17px;
                    height: 10px;
                }
            }
        } 
    }
}

.contact-info{ 
    i{ 
        width: 25px;
        display: inline-block;
        margin-right: 0;
        padding-right: 0;
    }
    p{
        padding-left: 30px;
    }
}

footer {
    .footer-try{
        padding: 30px 0;
        p{ 
            color: #FFF;
            font-size: 20px;
            font-weight: 700;
            line-height: 1.7;   
            margin-bottom: 0 !important;
        }
    }
    .footer-widgets {  
        border-top: 1px solid #222;
        padding-top: 50px;
        padding-bottom: 50px; 
        p{
            margin-bottom: 0 !important;
        }
    }
    .footer-menu {
        display: inline-block;
        width: auto;
        li {
            display: inline-block;
            margin-bottom: 0;
            margin-left: 25px;
            a{
                color: #fff;
                &:hover{
                    color:#7c4fe0;  
                }
            }
        }
    }
}

.text-right{
    text-align: right;
}

.text-opacity-25 {
    opacity: 0.25;
}

.contact-form-result {
    margin-top: 20px;
}

#contact-form{ 
    .form-group{
        position: relative;
        .error {
            display: block; 
            top: 18px;
            right: 15px;
        }
    }
}
 
@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
    body{  
        header .main-menu-area{
            .nav-menu {
                li a.btn-light{
                    background: #7c4fe0;
                    color: #fff;
                    margin: 15px 0 0 0 !important;
                    width: auto;
                    display: inline-block;
                    padding: 10px 20px; 
                    &:hover{
                        background: #fff;
                        color:#7c4fe0;
                    }
                }
                li a:hover {
                    color: #7c4fe0 !important;
                } 
            }
        } 
        .footer-copyright{
            *{

                text-align: center !important;
            }
            p{
                margin-bottom: 20px;
            }
        } 
        .footer-widgets{
            .d-flex { 
                flex-direction: column;
            }
            .footer-logo{
                margin-bottom: 20px !important; 
            }
        }
    } 
}

@media only screen and (max-width: 767px) {

    footer{
        .footer-menu{
            li { 
                margin-left: 15px;
                margin-right: 15px;
            }
        }

        .footer-try{ 
            text-align: center;
            p{ 
                margin-bottom: 20px !important; 
            }
            .d-flex { 
                flex-direction: column;
            }
        }
    }
  
}

@media only screen and (max-width: 599px) {

}
