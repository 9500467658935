// FONTS
@import url('https://fonts.googleapis.com/css?family=Roboto:200,300,400,500,700');

// COMPONENTS 
@import "components/bootstrap";
@import "components/fontawesome";
@import "components/linea-ecommerce";
@import "components/linea-basic";
@import "components/linea-elaboration";
@import "components/linea-linea-arrows";
@import "components/linea-music";
@import "components/linea-software";
@import "components/linea-weather";
@import "components/animate";
@import "components/lightcase";
@import "components/swiper-bundle";
@import "components/owl.carousel";
@import "components/slick";
@import "components/magnific-popup";
@import "components/jquery.mb.ytplayer";
@import "components/main";
@import "components/media";
@import "components/ionicons"; 
@import "components/cookie-consent"; 
@import "colors/blue"; 
@import "custom"; 
 